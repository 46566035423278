<template>
  <div>
    <el-form
      ref="basicForm"
      :model="formData"
      label-width="120px"
      :rules="basicFormRules"
    >
      <el-form-item label="专家名称:" prop="name">
        <el-input
          v-model="formData.name"
          placeholder="请输入专家名称"
          style="width: 50%"
        ></el-input>
      </el-form-item>
      <el-form-item label="昵称:" prop="nikeName">
        <el-input
          v-model="formData.nikeName"
          placeholder="请输入昵称"
          style="width: 50%"
        ></el-input>
      </el-form-item>
      <el-form-item label="专家性别:" prop="gender">
        <div>
          <el-radio-group v-model="formData.gender">
            <el-radio :label="1" :value="1">男士</el-radio>
            <el-radio :label="2" :value="2">女士</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label="专家类别:" prop="specialistType">
        <div>
          <el-radio-group v-model="formData.specialistType">
            <el-radio :label="1" :value="1">平台专家</el-radio>
            <el-radio :label="2" :value="2">渠道专家</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label="专家头像:" prop="headPortrait">
        <div class="cover">
          <div class="img_box">
            <img-big-upload
              :imageShow.sync="formData.headPortrait"
              :addTitleShow="false"
              @removeImg="removeImgHp"
              @uploadOneImgShow="uploadOneImgShowHp"
            />
            <span style="color: #146aff"
              >建议图片整体尺寸为229*131 px大小不超过12MB</span
            >
          </div>
        </div>
      </el-form-item>
      <el-form-item label="专家分享图:" prop="appLinkPicture">
        <div class="cover">
          <div class="img_box">
            <img-big-upload
              :imageShow.sync="formData.appLinkPicture"
              :addTitleShow="false"
              @removeImg="removeImgPicture"
              @uploadOneImgShow="uploadOneImgShowPicture"
            />
            <span style="color: #146aff"
              >建议图片整体尺寸为229*131 px大小不超过12MB</span
            >
          </div>
        </div>
      </el-form-item>
      <el-form-item label="专家封面图:" prop="cover">
        <div class="cover">
          <div class="img_box">
            <img-big-upload
              :imageShow.sync="formData.cover"
              :addTitleShow="false"
              @removeImg="removeImgDianspeci"
              @uploadOneImgShow="uploadOneImgShowDianspeci"
            />
            <span style="color: #146aff"
              >建议图片整体尺寸为229*131 px大小不超过12MB</span
            >
          </div>
          <div v-if="formData.cover">缩略图</div>
          <div v-if="formData.cover" class="thumbnail">
            <div class="thumbnail_box">
              <img :src="formData.cover" alt="" />
              <div>图片尺寸：229*131 px</div>
              <div>图片大小：4MB</div>
              <div>展示位置：首页</div>
            </div>

            <div class="thumbnail_box">
              <img :src="formData.cover" alt="" />
              <div>图片尺寸：229*131 px</div>
              <div>图片大小：10MB</div>
              <div>展示位置：首页banner区域</div>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="一句话介绍:" prop="specialistBrief">
        <div class="txtree">
          <el-input
            v-model="formData.specialistBrief"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 4 }"
            placeholder="请输入一句话简介"
            maxlength="50"
            show-word-limit
            style="width: 50%"
          />
        </div>
      </el-form-item>
      <el-form-item
        label="所在地区:"
        prop="activityProvince"
        class="informationType"
      >
        <div>
          <el-select
            v-model="formData.province"
            placeholder="请选择省份"
            @change="citychange"
          >
            <el-option
              v-for="item in operatingList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <div style="display: inline-block; width: 10px"></div>
          <el-select v-model="formData.city" placeholder="请选择地区">
            <el-option
              v-for="item in operatingLists"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="专家简介:" prop="specialistIntro">
        <div class="editBarBox">
          <div class="editBar">
            <Editorbar
              v-model="formData.specialistIntro"
              :isClear="isClear"
              style="width: 50%"
            />
            <div class="img_tips" style="width: 700px">
              <span
                >(图片的建议尺寸长为750px，富文本内图片格式应为jpg、jpeg、png，图片应小于12MB)
              </span>
            </div>
          </div>
        </div>
      </el-form-item>

      <div
        style="display: flex; width: 493px"
        v-for="(item, index) in formData.field"
        :key="index"
      >
        <div style="display: flex; flex-direction: column">
          <el-form-item label="擅长领域:" prop="field">
            <el-input
              v-model="formData.field[index].field"
              style="margin-bottom: 20px; width: 493px"
            />
            <el-input
              v-model="formData.field[index].fieldIntro"
              type="textarea"
              :autosize="{ minRows: 8, maxRows: 8 }"
              placeholder="请输入一句话简介"
              maxlength="60"
              show-word-limit
              style="margin-bottom: 20px; width: 493px"
            />
          </el-form-item>
        </div>
        <span style="color: #4e93fb" @click="addArr(item)">
          <i class="el-icon-circle-plus-outline"></i>
        </span>
        <span v-if="index > 0" style="color: #ff7575" @click="delArr(index)">
          <i class="el-icon-remove-outline"></i>
        </span>
      </div>
      <el-form-item label="报告附件:" prop="specialistFile" class="fileName">
        <el-input
          v-model="formData.specialistFile"
          readonly
          style="width: 42%"
        />
        <upload-report-file @upFileKey="fileKey" @fileSucee="successUpload" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Editorbar from "@/components/Editorbar";
import { getProvinceAllCityResult } from "@/api/expert.js";
import UploadReportFile from "../../ResearchReport/com/uploadReportFile.vue";
export default {
  name: "",
  components: {
    Editorbar,
    UploadReportFile,
  },
  data() {
    return {
      isClear: null,
      operatingList: [], //省
      operatingLists: [], //市
      // 表单数据
      formData: {
        name: "", //专家名称
        nikeName: "", //昵称
        gender: "", // 性别-1男/2女
        headPortrait: "", //专家头像
        appLinkPicture: "", //专家分享图
        cover: "", //专家封面图
        specialistBrief: "", //一句话介绍
        specialistIntro: "", //专家简介
        province: "", //省
        city: "", //市
        //擅长领域
        field: [
          {
            field: "",
            fieldIntro: "",
          },
        ],
        specialistType: null, //1-平台专家，总台新增，2-渠道专家
        id: null,
        specialistFile: "",
        specialistFileKey: "",
      },
      // 表单校验
      basicFormRules: {
        name: [{ required: true, message: "请输入专家名称", trigger: "blur" }],
        specialistBrief: [
          { required: true, message: "请输入一句话介绍", trigger: "blur" },
        ],
        specialistIntro: [
          { required: true, message: "请输入专家简介", trigger: "change" },
        ],
        cover: [
          { required: true, message: "请上传专家封面图", trigger: "change" },
        ],
        specialistType: [
          { required: true, message: "请选择专家简介", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.incity();
  },
  methods: {
    //(报告附件/文件名)文件上传成功返回的名字
    successUpload(val) {
      this.formData.specialistFile = val;
    },
    //(报告附件/文件名)文件上传返回的参数
    fileKey(val) {
      this.formData.specialistFileKey = val;
    },
    //删除专家头像
    removeImgHp(val) {
      this.formData.headPortrait = "";
    },
    //添加专家头像
    uploadOneImgShowHp(val) {
      this.formData.headPortrait = val;
    },
    //删除专家分享图
    removeImgPicture(val) {
      this.formData.appLinkPicture = "";
    },
    //添加专家分享图
    uploadOneImgShowPicture(val) {
      this.formData.appLinkPicture = val;
    },
    //删除专家封面图
    removeImgDianspeci(val) {
      this.formData.cover = "";
    },
    //添加专家封面图
    uploadOneImgShowDianspeci(val) {
      this.formData.cover = val;
    },
    // 添加擅长领域
    addArr() {
      this.formData.field.push({
        field: "",
        fieldIntro: "",
      });
    },
    delArr(index) {
      this.formData.field.splice(index, 1);
    },
    //城市
    async incity() {
      const res = await getProvinceAllCityResult();
      if (res.code == 200) {
        this.operatingList = res.data;
      }
    },
    // 改变城市
    citychange(item) {
      this.operatingList.forEach((o) => {
        if (this.formData.province == o.name) {
          this.formData.city = "";
          if (item == "全部") {
            this.operatingLists = this.operatingList
              .map((el) => el.cityResults)
              .flat(Infinity);
          } else {
            this.operatingLists = this.operatingList.find(
              (el) => el.name == item
            ).cityResults;
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.img_tips {
  font-size: 12px;
  color: #6ba1ff;
}
/deep/ .el-icon-circle-plus-outline {
  font-size: 31px;
  vertical-align: middle;
  color: #698eef;
  cursor: pointer;
  padding-left: 10px;
}
/deep/ .el-icon-remove-outline {
  font-size: 31px;
  vertical-align: middle;
  color: red;
  cursor: pointer;
  padding-left: 10px;
}
.cover {
  display: flex;
  flex-direction: column;
}
.thumbnail {
  display: flex;
  color: #bebebe;
}
.thumbnail img {
  width: 143.23px;
  height: 81.63px;
  border-radius: 4px;
}
.thumbnail video {
  width: 160.28px;
  height: 91.35px;
  border-radius: 4px;
}

.thumbnail div {
  margin-right: 20px;
}
.thumbnail_box div {
  display: flex;
  flex-direction: column;
  height: 30px;
}
.fileName {
  /deep/.el-form-item__content {
    position: relative;
    display: flex;
    :nth-child(2) {
      position: relative;
      left: 0;
      height: 40px;
      .el-button {
        height: 40px;
      }
    }
  }
  /deep/.upload-demo {
    margin-left: 10px;
  }
}
</style>
