import request from "@/utils/request"

//后台-专家服务数据一览
export function getCompanySpecialistList(data) {
  return request({
    url: '/serverSpecialist/getCompanySpecialistList',
    method: 'post',
    data
  })
}
//单个数据专家详情
export function getById(query) {
  return request({
    url: '/serverSpecialist/getById',
    method: 'get',
    params: query
  })
}
//新增专家
export function save(data) {
  return request({
    url: '/serverSpecialist/insert',
    method: 'post',
    data
  })
}
//编辑专家
export function update(data) {
  return request({
    url: '/serverSpecialist/update',
    method: 'post',
    data
  })
}
//自动匹配
export function selectMatching(data) {
  return request({
    url: '/serverSpecialist/selectMatching',
    method: 'post',
    data
  })
}
//加入已选
export function insertSelectedCompany(data) {
  return request({
    url: '/serverSpecialist/insertSelectedCompany',
    method: 'post',
    data
  })
}
//已经匹配
export function selectedCompanyPage(data) {
  return request({
    url: '/serverSpecialist/selectedCompanyPage',
    method: 'post',
    data
  })
}
//发布已选
export function releaseCompany(data) {
  return request({
    url: '/serverSpecialist/releaseCompany',
    method: 'post',
    data
  })
}
//移出已选
export function deleteCorrelation(data) {
  return request({
    url: '/serverSpecialist/deleteCorrelation',
    method: 'post',
    data
  })
}
//新增专家标签
export function savelabel(data) {
  return request({
    url: '/serverSpecialist/insertActivityLabel',
    method: 'post',
    data
  })
}
//编辑专家标签
export function updateInformationLabel(data) {
  return request({
    url: '/serverSpecialist/updateActivityLabel',
    method: 'post',
    data
  })
}
//专家详情
export function getBylabelId(query) {
  return request({
    url: '/serverSpecialist/getBySpecialistLabelId',
    method: 'get',
    params: query
  })
}
//咨询用户数据一览
export function selectConsultingUser(data) {
  return request({
    url: '/serverSpecialist/selectConsultingUser',
    method: 'post',
    data
  })
}
//运营人员修改用户联系专家的状态
export function concatUser(query) {
  return request({
    url: '/serverSpecialist/concatUser',
    method: 'get',
    params: query
  })
}
//批量设置专家类型---通用版
export function updateGeneralVersion(data) {
  return request({
    url: '/serverSpecialist/updateGeneralVersion',
    method: 'post',
    data
  })
}
//解冻专家
export function cancelFreezeSpecialist(query) {
  return request({
    url: '/serverSpecialist/cancelFreezeSpecialist',
    method: 'get',
    params: query
  })
}
//冻结专家
export function freezeSpecialist(query) {
  return request({
    url: '/serverSpecialist/freezeSpecialist',
    method: 'get',
    params: query
  })
}
//专家查看一览查询
export function listUserCheck(data) {
  return request({
    url: '/serverSpecialist/listUserCheck',
    method: 'post',
    data
  })
}
//专家分享一览查询
export function listUserShare(data) {
  return request({
    url: '/serverSpecialist/listUserShare',
    method: 'post',
    data
  })
}

//已推送企业数据一览-专家
export function pushedCompanyPageSpecialist(data) {
  return request({
    url: '/serverSpecialist/pushedCompanyPageSpecialist',
    method: 'post',
    data
  })
}
//专家配置智参中心数据一览
export function wisdomGinsengPage(data) {
  return request({
    url: '/serverSpecialist/wisdomGinsengSpecialistPage',
    method: 'post',
    data
  })
}
//专家配置智参中心上架
export function wisdomGinsengOnSelf(data) {
  return request({
    url: '/serverSpecialist/wisdomGinsengOnSelf',
    method: 'post',
    data
  })
}
//专家配置智参中心下架
export function wisdomGinsengOffSelf(data) {
  return request({
    url: '/serverSpecialist/wisdomGinsengOffSelf',
    method: 'post',
    data
  })
}
//批量设置专家下架
export function updateOffShelf(data) {
  return request({
    url: '/serverSpecialist/updateOffShelf',
    method: 'post',
    data
  })
}
//批量设置专家上架
export function updateOnShelf(data) {
  return request({
    url: '/serverSpecialist/updateOnShelf',
    method: 'post',
    data
  })
}
//批量设置专家类型---通用版 (配置智参中心)
export function updateWisdomGinseng(data) {
  return request({
    url: '/serverSpecialist/updateWisdomGinseng',
    method: 'post',
    data
  })
}
//手动配置企业
export function artificialSelectMatching(data) {
  return request({
    url: '/serverSpecialist/artificialSelectMatching',
    method: 'post',
    data
  })
}


// ------------------专家模块
//查询
export function getByIdEx(query) {
  return request({
    url: 'system/specialist/getById',
    method: 'get',
    params: query
  })
}

// 一览
export function getListByCondition(data) {
  return request({
    url: 'system/specialist/getListByCondition',
    method: 'post',
    data
  })
}

// 删除/冻结
export function deleteEx(data) {
  return request({
    url: 'system/specialist/delete',
    method: 'post',
    data
  })
}

// 激活
export function canelFreezeSpecialist(query) {
  return request({
    url: 'system/specialist/cancelFreezeSpecialist',
    method: 'get',
    params: query
  })
}


// 新增
export function insertEx(data) {
  return request({
    url: 'system/specialist/insert',
    method: 'post',
    data
  })
}

// 修改
export function updateEx(data) {
  return request({
    url: 'system/specialist/update',
    method: 'post',
    data
  })
}

// ---------------标签信息

// 专家标签-新增
export function insertSpecialist(data) {
  return request({
    url: 'system/specialistLabel/insertSpecialist',
    method: 'post',
    data
  })
}

// 专家标签信息详情 （specialistId）
export function selectSpecialistLabelDetail(query) {
  return request({
    url: 'system/specialistLabel/selectSpecialistLabelDetail',
    method: 'get',
    params: query
  })
}

// 手动打标签新增
export function insertSpecialistLabelManual(data) {
  return request({
    url: 'system/specialistLabel/insertSpecialistLabelManual',
    method: 'post',
    data
  })
}

// 手动打标签编辑更新
export function updateSpecialistManual(data) {
  return request({
    url: 'system/specialistLabel/updateSpecialistManual',
    method: 'post',
    data
  })
}

// 查询当前所有的标签
export function getByIdSd(query) {
  return request({
    url: 'system/specialistLabel/getById',
    method: 'get',
    params: query
  })
}

// 保存半自动化标签
export function insertMatchSpecialistLabel(data) {
  return request({
    url: 'system/specialistLabel/insertMatchSpecialistLabel',
    method: 'post',
    data
  })
}

// 获取自动匹配历史标签
export function findHistroyMatchedLabelBySpecialist(query) {
  return request({
    url: 'system/label/findHistroyMatchedLabelBySpecialist',
    method: 'get',
    params: query
  })
}


//获取省
export function getProvince() {
  return request({
    url: 'system/city/getProvinceAll',
    method: 'get'
  })
}

// 获取所在地区
export function getProvinceAllCityResult() {
  return request({
    url: 'system/city/getProvinceAllCityResult',
    method: 'get'
  })
}

// 专家导入
export function importExcelZj(data) {
  return request({
    url: 'system/specialist/importExcel',
    method: 'post',
    data
  })
}
