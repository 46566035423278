<!-- 这里是包含所有子组件的页面 -->
<template>
  <div class="expertInfo">
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="基本信息" name="first">
          <basicInformation ref="basicInformation"></basicInformation>
        </el-tab-pane>
        <el-tab-pane label="标签信息" name="second">
          <labelInfo ref="labelInfo"></labelInfo>
        </el-tab-pane>
        <el-tab-pane label="手动标签" name="third">
          <labelManagement
            ref="labelManagement"
            :id="String(id)"
            :saveId="String(saveId)"
          ></labelManagement>
        </el-tab-pane>
        <el-tab-pane label="半自动化标签" name="four">
          <robotizationlabel
            ref="robotizationlabel"
            :id="String(id)"
            :saveId="String(saveId)"
          ></robotizationlabel>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <div
      style="height: 50px; background: #fff;"
      v-if="this.activeName == 'four'"
    ></div>
    <!-- v-if="!personType" -->
    <div class="bottom" v-if="activeName !== 'four'">
      <el-button type="primary" @click="saveItem">保存</el-button>
      <el-button @click="cancelItem">取消</el-button>
    </div>
  </div>
</template>

<script>
import basicInformation from "./basicInformation.vue";
import labelInfo from "./labelInfo.vue";
import labelManagement from "./labelManagement.vue";
import robotizationlabel from "./robotizationlabel.vue";
// 基本信息新增/基本信息修改/基本信息查询
// 标签信息新增/标签信息查询
// 手动标签新增/手动标签编辑
import {
  insertEx,
  updateEx,
  getByIdEx,
  insertSpecialist,
  selectSpecialistLabelDetail,
  insertSpecialistLabelManual,
  updateSpecialistManual,
} from "@/api/expert.js";
export default {
  name: "",
  components: {
    basicInformation,
    labelInfo,
    labelManagement,
    robotizationlabel,
  },
  computed: {
    // 从专家标签进来要调到手动标签，带过来的是third
    headTabActive() {
      return String(this.$route.query.headTabActive);
    },
    // 从首页进来带的当前编辑数据id
    id() {
      return String(this.$route.query.id);
    },
  },
  watch: {
    // 监听
    headTabActive: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.activeName = newVal;
        }
      },
      immediate: true,
    },
    // 监听是否在手动标签页面
    activeName: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal == "third") {
          this.$refs.labelManagement.getBylabelId();
        }
      },
    },
  },
  data() {
    return {
      activeName: "first", // 当前标签页
      saveId: null, //新增唯一id
    };
  },
  created() {
    if ((this.id && this.id !== "undefined") || this.saveId) {
      this.selectSpecialistLabelDetail();
      this.getByIdEx();
    }
  },
  methods: {
    // 切换tabs页(切换到手动标签,调用查询回显的数据)
    handleClick(tab, event) {
      if (tab.index == 2 && this.saveId) {
        this.$refs.labelManagement.getBylabelId();
      }
    },
    // 保存接口
    saveItem() {
      let res;
      // 判断基本信息页面的必填字段有没有填
      switch (this.activeName) {
        case "first":
          this.$refs.basicInformation.$refs.basicForm.validate(
            async (valid) => {
              if (valid) {
                // 0 新增 1 编辑
                if ((this.id && this.id !== "undefined") || this.saveId) {
                  res = await updateEx(this.$refs.basicInformation.formData);
                } else {
                  res = await insertEx(this.$refs.basicInformation.formData);
                }

                if (res.code === 200) {
                  // this.$message.success(`${this.id ? "编辑" : "新增"}成功`);
                  if ((this.id && this.id !== "undefined") || this.saveId) {
                    this.$message.success("编辑成功");
                  } else {
                    this.$message.success("新增成功");
                  }

                  if (res.data !== "修改成功" && res.data !== "") {
                    this.saveId = res.data;
                    this.$refs.basicInformation.formData.id = res.data;
                  }

                  this.activeName = "second";
                } else {
                  this.$message.error(res.msg);
                }
              }
            }
          );

          break;
        case "second":
          //最终组装的数组
          let arr = [];
          //开始添加
          this.$refs.labelInfo.specialistParam.specialistKeywordAddParamList.forEach(
            (item) => {
              let keyword = item.keyword;
              let keywordDescribe = item.keywordDescribe;
              let specialistLabelList = [];
              // 遍历我添加的数组
              item.specialistLabelList.forEach((child) => {
                // 遍历总数组
                this.$refs.labelInfo.labelOptions.forEach((item1) => {
                  if (item1.labelKeywordResultList[0].id == child.labelId) {
                    item1.labelKeywordResultList.forEach((name) => {
                      name = {
                        groupLabelId: name.groupLabelPrefix,
                        groupLabelName: name.groupLabelType,
                        labelId: name.id,
                        labelName: name.labelName,
                        labelStatus:
                          item1.labelKeywordResultList[0].id == name.id ? 1 : 0,
                      };
                      specialistLabelList.push(name);
                    });
                  }
                });
              });

              let all = {
                keyword: keyword,
                keywordDescribe: keywordDescribe,
                specialistLabelList: specialistLabelList,
              };
              arr.push(all);
            }
          );

          // 最终提交后端的数据
          let specialistParam = {
            keywordVersion: this.$refs.labelInfo.specialistParam.keywordVersion, //关键字版本
            specialistId: this.saveId ? this.saveId : this.id,
            specialistKeywordAddParamList: arr,
          };
          // 判断是编辑还是新增 1编辑  0新增
          if (!this.id) {
            // 如果未保存基本信息
            if (!Number(this.saveId)) {
              this.$message.error("请先保存基本信息的数据再进行下一步操作！");
            } else {
              this.$refs.labelInfo.$refs.labelInfoForm.validate(
                async (valid) => {
                  if (valid) {
                    res = await insertSpecialist(specialistParam);
                    if (res.code == 200) {
                      this.$message.success("新增成功!");
                      this.activeName = "third";
                    } else {
                      this.$message.error("新增失败，请稍候重试!");
                    }
                  }
                }
              );
            }
          } else {
            this.$refs.labelInfo.$refs.labelInfoForm.validate(async (valid) => {
              if (valid) {
                res = await insertSpecialist(specialistParam);
                if (res.code == 200) {
                  this.$message.success("编辑成功!");
                  this.activeName = "third";
                } else {
                  this.$message.error("编辑失败，请稍候重试!");
                }
              }
            });
          }
          break;
        case "third":
          if (!this.id) {
            if (!this.saveId) {
              this.$message.error("请先保存基本信息的数据再进行下一步操作！");
            } else {
              let query = [];

              this.$refs.labelManagement.radiolist.forEach((el) => {
                if (el.labelparam.labelNameList.length > 0) {
                  el.labelparam.specialistId = this.saveId
                    ? this.saveId
                    : this.id;
                  query.push(el.labelparam);
                }
              });
              if (query.length > 0) {
                if (!this.$refs.labelManagement.labeList.length) {
                  insertSpecialistLabelManual(query).then((response) => {
                    if (response.code == 200) {
                      this.$message.success("保存成功");
                      this.activeName = "four";
                    } else {
                      this.$message.error(response.data.message);
                    }
                  });
                } else {
                  updateSpecialistManual(query).then((response) => {
                    if (response.code == 200) {
                      this.$message.success("编辑成功");
                      this.activeName = "four";
                    } else {
                      this.$message.error(response.data.message);
                    }
                  });
                }
              } else {
                this.$message.warning("请勾选标签！");
              }
            }
          } else {
            let query = [];

            this.$refs.labelManagement.radiolist.forEach((el) => {
              if (el.labelparam.labelNameList.length > 0) {
                el.labelparam.specialistId = this.saveId
                  ? this.saveId
                  : this.id;
                query.push(el.labelparam);
              }
            });
            if (query.length > 0) {
              if (!this.$refs.labelManagement.labeList.length) {
                insertSpecialistLabelManual(query).then((response) => {
                  if (response.code == 200) {
                    this.$message.success("保存成功");
                    this.activeName = "four";
                  } else {
                    this.$message.error(response.data.message);
                  }
                });
              } else {
                updateSpecialistManual(query).then((response) => {
                  if (response.code == 200) {
                    this.$message.success("编辑成功");
                    this.activeName = "four";
                  } else {
                    this.$message.error(response.data.message);
                  }
                });
              }
            } else {
              this.$message.warning("请勾选标签！");
            }
          }

          break;
        case "four":
          break;
        default:
          break;
      }
    },
    // 取消按钮
    cancelItem() {
      this.$router.go(-1);
    },
    // 案例标签信息详情
    async selectSpecialistLabelDetail() {
      const res = await selectSpecialistLabelDetail({
        specialistId: Number(this.saveId) ? Number(this.saveId) : this.id,
      });
      if (res.code == 200) {
        // 如果请求的标签数据为null的情况，就处理下
        if (!res.data.specialistKeywordAddParamList) {
          this.$refs.labelInfo.specialistParam = {
            specialistId: "", // 案例主键
            keywordVersion: "", // 关键字版本
            // type: null,
            specialistKeywordAddParamList: [
              {
                specialistLabelList: [
                  {
                    groupLabelId: "", // 标签组编号
                    groupLabelName: "", // 标签组名称
                    labelId: "", // 标签编号
                    labelName: "", // 标签内容
                    reportKeywordId: null,
                    id: null,
                    superiorLabel: null, // 上级标签
                  },
                ],
                keyword: "", // 关键词
                keywordDescribe: "", // 关键词数据
              },
            ],
          };
        } else {
          this.$refs.labelInfo.specialistParam = res.data;
          // 请求数据回来后，将标签内容也改为（标签组名称+标签内容）
          res.data.specialistKeywordAddParamList.forEach((item) => {
            item.specialistLabelList.forEach((child) => {
              child.labelName = child.groupLabelName + "/" + child.labelName;
            });
          });
        }
      }
    },
    // 报告基本信息详情
    async getByIdEx() {
      const res = await getByIdEx({
        specialistId: Number(this.saveId) ? Number(this.saveId) : this.id,
      });
      if (res.code == 200) {
        this.$refs.basicInformation.formData = res.data;
      }
      // 如果擅长领域为空的话
      if (!res.data.field) {
        this.$refs.basicInformation.formData.field = [
          {
            field: "",
            fieldIntro: "",
          },
        ];
      }
      if (!res.data.headPortrait) {
        this.$refs.basicInformation.formData.headPortrait = "";
      }
      if (!res.data.appLinkPicture) {
        this.$refs.basicInformation.formData.appLinkPicture = "";
      }
      if (!res.data.cover) {
        this.$refs.basicInformation.formData.cover = "";
      }
      // 上传附件
      // if (this.caseDataInfo.specialistFile) {
      //   this.caseDataInfo.specialistFile =
      //     this.caseDataInfo.specialistFile.substring(
      //       this.caseDataInfo.specialistFile.lastIndexOf("/") + 1
      //     );
      // }
    },
  },
};
</script>

<style scoped lang="less">
.expertInfo {
  width: 100%;
  /deep/.el-card__body {
    padding: 0 20px;
  }
}
/deep/.el-tabs__content {
  overflow: scroll !important;
}
</style>
